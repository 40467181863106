@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');


*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Oxygen", sans-serif;
}

                