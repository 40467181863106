
.navbar{
  width: 100%;
  padding: 20px 5%;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.logobox{
  width: 200px;
}
.logo1{
  width: 100px;
}
.buttonholder{
 display: flex;
 gap: 20px;
 padding: 5px;
}
.btn1{
  padding: 10px 20px;
  font-weight: 499;
  background-color:#fff ;
  border: solid 1px #22331f;
  border-radius: 5px;
}
.btn2{
  padding: 10px 20px;
  font-weight: 499;
  background-color:#22331f ;
  color: #fff;
  border-radius: 5px;
  border: none;
}
.iconbox{
  display: none;
}
.hamburger{
  display: none;
}

@media screen and (max-width: 699px){
  .buttonholder{
    display: none;
  }
  .navbar{
    justify-content: flex-start;
  }
  .logobox{display: none;}
  .hamburger{
    position:absolute;
    top: 53px;
    left: -80%;
    width: 70%;
    height: 100%;
    background-color: #fff;
    display: inline-block;
    padding: 20px;
    z-index: 5;
  }
  .hamburger div{
    display: flex;
    width: 100%;
    height: 100%;
    gap: 20px;
    flex-direction: column;
  }
  .iconbox{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .close.not-active{
    position: absolute;
    display: none;
  }
  .overlay{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .overlay.is-active{
    display: block;
  }
}
